import React from 'react';

import Container from 'react-bootstrap/Container'

import BecomeAuthor from './BecomeAuthor.js'

import jupyterLogo from '../assets/images/jupyterLogo.svg';




class About extends React.Component {
  render() {
    return (
          <Container>
            <div className='about'>
  	          <p style={{fontWeight: 700, fontSize: '1.3em'}}>
                We publish outstanding tech works focused on a hands-on approach to help people learn faster.
              </p>
              <p>
                We simplify the steps from draft to publication by automating the conversion from notebooks to
                ebooks, and pdf.
                As a technical author, you focus on the content.
               </p>
            </div>
            <BecomeAuthor />
          </Container>
    );
  }
}


export default About;
