import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import background from '../assets/images/DSC_1027.jpg';
import logo from '../assets/images/Haliotis_diversicolor_01.png';


class Headline extends React.Component {
  render() {
    return (

      <div className="headline" style={{backgroundImage: `url(${background})`, backgroundSize: 'cover', }}>
          <Container>
	          <Row>
	        	<Col xs={1} className="text-right">
          			<img src={logo} alt="" width="40px" title="haliotis" />
          		</Col>
          		<Col xs={11} className="align-self-center">
          			<h1 className="cinzel" style={{margin: 0}}>Haliotis Publishing</h1>
          			<p className="anton">Tech publication focused on learning.</p>
          		</Col>
          	</Row>
          </Container>
      </div>
    );
  }
}


export default Headline;
