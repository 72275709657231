import React from 'react';

import Headline from './components/Headline.js';
import About from './components/About.js';


function App() {
  return (
    <div>
      <Headline />
      <About />
    </div>
  );
}

export default App;
