import React from 'react';

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'


import jupyterLogo from '../assets/images/jupyterLogo.svg';


class BecomeAuthor extends React.Component {
  render() {
    return (
          <Container>
            <Card className="shadow p-3 mb-5 bg-white become-author">
              <div className="text-center" style={{fontWeight: 700}}>
                Become an author
              </div>
              <Card.Body className="text-center">
                <Card.Text>
                    You write in Jupyter Notebooks <img src={jupyterLogo} alt="" width="40px" title="jupyterLogo" />,<br/>
                    we <span>publish it</span>.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                    <div className="text-center">
                      Interested? Contact us at <br/>
                      author@haliotis-publishing.com
                    </div>
              </Card.Footer>
            </Card>
          </Container>
    );
  }
}


export default BecomeAuthor;
